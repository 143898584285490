import { useEffect, useState } from "react";
import "./App.css";

let WAIT_MS = 400;

function App() {
  let [clicked, setClicked] = useState<boolean>(false);
  let [audioUrl, setAudioUrl] = useState<string>("yell1.wav");

  let [big, setBig] = useState<boolean>(false);

  // @ts-ignore
  let audio = new Audio(audioUrl);
    // `/public/yell${Math.floor(Math.random() * 6)}.wav`);

  let animate = async () => {
    audio.play();
    setClicked(true);
    await sleep(WAIT_MS);
    setClicked(false);
    setAudioUrl(`/yell${Math.floor(Math.random() * 6)}.wav`);
    setBig(Math.floor(Math.random() * 5) == 0);
  }

  // useEffect(() => {
  //   load("public/ron1.png")
  //   load("public/ron2.png")
  // })

  return (
    <div className="App">
      <div onClick={() => {animate()}} className="center">
        {
          clicked ? 
            (big ? <img src="ron3.jpg" alt="ron3" /> : <img src="ron2.jpg" alt="ron2" />)
            :
            <img src="ron1.jpg" alt="ron1" />
        }
      </div>
      <div className="footer">
        <i>aaron iyer</i> – created by <a href="https://twitter.com/samrags_">@samrags_</a> and <a href="https://twitter.com/sproule_">@sproule_</a>
      </div>
    </div>
  );
}

async function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function load(addr: string) {
  // @ts-ignore
  let img = new Image()
  img.src = "addr"
}

export default App;
